import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "everything-you-need-to-work-with-learn-about-and-contribute-to-carbon"
    }}>{`Everything you need to work with, learn about, and contribute to Carbon.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Development resources</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Design resources</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Tools</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Reading</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "development-resources"
    }}>{`Development resources`}</h2>
    <h3 {...{
      "id": "github-repos"
    }}>{`GitHub repos`}</h3>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon Components" href="https://github.com/ibm/carbon-components" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon Components React" href="https://github.com/ibm/carbon-components-react" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon Components Angular" href="https://github.com/ibm/carbon-components-angular" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon Components Vue" href="https://github.com/carbon-design-system/carbon-components-vue" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon boilerplate" href="https://github.com/carbon-design-system/carbon-boilerplate" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "storybooks"
    }}>{`Storybooks`}</h3>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon React Storybook" href="http://react.carbondesignsystem.com/" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "react icon",
              "title": "react icon",
              "src": "/static/8cf88522cf29f15ed07205684ebef753/7d71f/react-icon.png",
              "srcSet": ["/static/8cf88522cf29f15ed07205684ebef753/7d71f/react-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon Components Vue Storybook" href="http://vue.carbondesignsystem.com/" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "128px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "vue icon",
              "title": "vue icon",
              "src": "/static/988dc0d391f0a74acf4433594029db2c/c8369/vue-icon.png",
              "srcSet": ["/static/988dc0d391f0a74acf4433594029db2c/c8369/vue-icon.png 128w"],
              "sizes": "(max-width: 128px) 100vw, 128px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "design-resources"
    }}>{`Design resources`}</h2>
    <h3 {...{
      "id": "theme-libraries"
    }}>{`Theme libraries`}</h3>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="White theme" href="sketch://add-library/cloud/JaVzz" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Gray 10 theme" href="sketch://add-library/cloud/Onwv2" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Gray 90 theme" href="sketch://add-library/cloud/eo37p" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Gray 100 theme" href="sketch://add-library/cloud/d13Ll" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "color-grid-and-icons"
    }}>{`Color, grid, and icons`}</h3>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="RGB color palettes (.ase and .clr)" href="https://www.carbondesignsystem.com/files/IBM_Colors_RGB.zip" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101.63678877630554%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "ase",
              "title": "ase",
              "src": "/static/5a9c67aa60ffd20d5598081b08a8ebc8/fb070/ase.png",
              "srcSet": ["/static/5a9c67aa60ffd20d5598081b08a8ebc8/d6747/ase.png 288w", "/static/5a9c67aa60ffd20d5598081b08a8ebc8/09548/ase.png 576w", "/static/5a9c67aa60ffd20d5598081b08a8ebc8/fb070/ase.png 1152w", "/static/5a9c67aa60ffd20d5598081b08a8ebc8/21b19/ase.png 1283w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd="4" colLg="4" noGutterSm mdxType="Column">
    <ResourceCard title="IBM Design Language library" href="sketch://add-library/cloud/75VZZ" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="IBM Grid template" href="https://sketch.cloud/s/ngV7z" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="UI Shell template" href="https://sketch.cloud/s/EjVmA" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "github-repos-1"
    }}>{`GitHub repos`}</h3>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon Design Kit" href="https://github.com/ibm/carbon-design-kit" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <br />
    <h4 {...{
      "id": "ibm-plex"
    }}>{`IBM Plex`}</h4>
    <p>{`Carbon uses the open-source typeface `}<a parentName="p" {...{
        "href": "https://github.com/IBM/plex"
      }}>{`IBM Plex`}</a>{` – carefully designed to meet IBM’s needs as a global technology company and reflect IBM’s spirit, beliefs, and design principles.`}</p>
    <h4 {...{
      "id": "ibm-design-language"
    }}>{`IBM Design Language`}</h4>
    <p>{`Carbon delivers the `}<a parentName="p" {...{
        "href": "https://www.ibm.com/design/language/"
      }}>{`IBM Design Language`}</a>{` as tools for designers and developers, guidance, tutorials, and support.`}</p>
    <h2 {...{
      "id": "tools"
    }}>{`Tools`}</h2>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Carbon CodePen" href="http://www.codepen.io/team/carbon" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "codepen icon",
              "title": "codepen icon",
              "src": "/static/3f7a8b3f535eba28197ce562e86099df/7d71f/codepen-icon.png",
              "srcSet": ["/static/3f7a8b3f535eba28197ce562e86099df/7d71f/codepen-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Color contrast checker" href="https://marijohannessen.github.io/color-contrast-checker/" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "color contrast icon",
              "title": "color contrast icon",
              "src": "/static/0221b06df5d630131fb1efd656f319b3/7d71f/color-contrast-icon.png",
              "srcSet": ["/static/0221b06df5d630131fb1efd656f319b3/7d71f/color-contrast-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "reading"
    }}>{`Reading`}</h2>
    <h3 {...{
      "id": "featured-stories"
    }}>{`Featured stories`}</h3>
    <Row mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Smashing Magazine's “Design Systems” Book" href="https://www.smashingmagazine.com/design-systems-book/" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.199999999999996%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 01",
              "title": "Featured 01",
              "src": "/static/529e9fe384d5fe1ba1314a1940e79338/70165/Featured_01.png",
              "srcSet": ["/static/529e9fe384d5fe1ba1314a1940e79338/d6747/Featured_01.png 288w", "/static/529e9fe384d5fe1ba1314a1940e79338/70165/Featured_01.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Winter 2017 HOW In-House Design Award Winner" href="http://www.howdesign.com/84-award-winning-projects-from-in-house-design-teams/" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.199999999999996%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 02",
              "title": "Featured 02",
              "src": "/static/a393925eb07480025abc2682f1ad7beb/70165/Featured_02.png",
              "srcSet": ["/static/a393925eb07480025abc2682f1ad7beb/d6747/Featured_02.png 288w", "/static/a393925eb07480025abc2682f1ad7beb/70165/Featured_02.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Path to Design System Maturity" href="https://medium.com/ux-power-tools/the-path-to-design-system-maturity-d403daba692a" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 03",
              "title": "Featured 03",
              "src": "/static/1b155b78187f8ee32488c8d5524dcb1e/fb070/Featured_03.png",
              "srcSet": ["/static/1b155b78187f8ee32488c8d5524dcb1e/d6747/Featured_03.png 288w", "/static/1b155b78187f8ee32488c8d5524dcb1e/09548/Featured_03.png 576w", "/static/1b155b78187f8ee32488c8d5524dcb1e/fb070/Featured_03.png 1152w", "/static/1b155b78187f8ee32488c8d5524dcb1e/dee76/Featured_03.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Responsive Web Design Podcast" href="https://responsivewebdesign.com/podcast/ibm-carbon/" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 04",
              "title": "Featured 04",
              "src": "/static/e78e6a09653d644ead610440b64a544c/fb070/Featured_04.png",
              "srcSet": ["/static/e78e6a09653d644ead610440b64a544c/d6747/Featured_04.png 288w", "/static/e78e6a09653d644ead610440b64a544c/09548/Featured_04.png 576w", "/static/e78e6a09653d644ead610440b64a544c/fb070/Featured_04.png 1152w", "/static/e78e6a09653d644ead610440b64a544c/dee76/Featured_04.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="UX Pin Virtual Design Summit" href="https://www.youtube.com/watch?v=eSvq5MieOdw&t=144s" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Featured 05",
              "title": "Featured 05",
              "src": "/static/958c87f4b1c00d6b9cc94c229111ede9/fb070/Featured_05.png",
              "srcSet": ["/static/958c87f4b1c00d6b9cc94c229111ede9/d6747/Featured_05.png 288w", "/static/958c87f4b1c00d6b9cc94c229111ede9/09548/Featured_05.png 576w", "/static/958c87f4b1c00d6b9cc94c229111ede9/fb070/Featured_05.png 1152w", "/static/958c87f4b1c00d6b9cc94c229111ede9/dee76/Featured_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "articles"
    }}>{`Articles`}</h3>
    <Row mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard type="article" title="Carbon June(ish) preview" author="Alison Joseph" date="June 12, 2019" href="https://medium.com/carbondesign/carbon-june-ish-preview-9c5843258933" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "june option 3",
              "title": "june option 3",
              "src": "/static/54a53bcd8760b98215387b6438fdf848/fb070/june_option_3.png",
              "srcSet": ["/static/54a53bcd8760b98215387b6438fdf848/d6747/june_option_3.png 288w", "/static/54a53bcd8760b98215387b6438fdf848/09548/june_option_3.png 576w", "/static/54a53bcd8760b98215387b6438fdf848/fb070/june_option_3.png 1152w", "/static/54a53bcd8760b98215387b6438fdf848/dee76/june_option_3.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard type="article" title="Carbon v10.3.0 (May 2019)" author="Josh Black" date="June 6, 2019" href="https://medium.com/carbondesign/carbon-v10-3-0-may-2019-41b1f970f0ce" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "10 3 image",
              "title": "10 3 image",
              "src": "/static/bf06d74c14c38c42cfc9243126462ec2/fb070/10_3-image.png",
              "srcSet": ["/static/bf06d74c14c38c42cfc9243126462ec2/d6747/10_3-image.png 288w", "/static/bf06d74c14c38c42cfc9243126462ec2/09548/10_3-image.png 576w", "/static/bf06d74c14c38c42cfc9243126462ec2/fb070/10_3-image.png 1152w", "/static/bf06d74c14c38c42cfc9243126462ec2/dee76/10_3-image.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard type="article" title="Essentials: Learn to build with Carbon" author="Matt Rosno" date="May 15, 2019" href="https://medium.com/carbondesign/essentials-learn-to-build-with-carbon-1d11ce05125f" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "code palette",
              "title": "code palette",
              "src": "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/fb070/code_palette.png",
              "srcSet": ["/static/1a67f5c2bd6548d9e8bb0368814a7d1b/d6747/code_palette.png 288w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/09548/code_palette.png 576w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/fb070/code_palette.png 1152w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/dee76/code_palette.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard type="article" title="Carbon is moving to a monorepo" author="Josh Black" date="May 10, 2019" href="https://medium.com/carbondesign/carbon-is-moving-to-a-monorepo-c6bfcbe87de0" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "mono repo teal",
              "title": "mono repo teal",
              "src": "/static/5912252ae7c2b12f24eaf492fe418d49/fb070/mono_repo_teal.png",
              "srcSet": ["/static/5912252ae7c2b12f24eaf492fe418d49/d6747/mono_repo_teal.png 288w", "/static/5912252ae7c2b12f24eaf492fe418d49/09548/mono_repo_teal.png 576w", "/static/5912252ae7c2b12f24eaf492fe418d49/fb070/mono_repo_teal.png 1152w", "/static/5912252ae7c2b12f24eaf492fe418d49/dee76/mono_repo_teal.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard type="article" title="Carbon dark themes are here" author="Lauren Rice" date="April 29, 2019" href="https://medium.com/carbondesign/carbon-dark-themes-are-here-a18910a0f910" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "carbon themes",
              "title": "carbon themes",
              "src": "/static/66fdcfa7ca2906fc119a1c7d32e38981/fb070/carbon_themes.png",
              "srcSet": ["/static/66fdcfa7ca2906fc119a1c7d32e38981/d6747/carbon_themes.png 288w", "/static/66fdcfa7ca2906fc119a1c7d32e38981/09548/carbon_themes.png 576w", "/static/66fdcfa7ca2906fc119a1c7d32e38981/fb070/carbon_themes.png 1152w", "/static/66fdcfa7ca2906fc119a1c7d32e38981/dee76/carbon_themes.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard type="article" title="Carbon v10 Release" author="Robin Cannon" date="April 4, 2019" href="https://medium.com/carbondesign/carbon-v10-release-812eacb7da7f" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "bee 70",
              "title": "bee 70",
              "src": "/static/76fff6ed801ff9bb85168eb592bba8b7/fb070/bee_70.png",
              "srcSet": ["/static/76fff6ed801ff9bb85168eb592bba8b7/d6747/bee_70.png 288w", "/static/76fff6ed801ff9bb85168eb592bba8b7/09548/bee_70.png 576w", "/static/76fff6ed801ff9bb85168eb592bba8b7/fb070/bee_70.png 1152w", "/static/76fff6ed801ff9bb85168eb592bba8b7/dee76/bee_70.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Design with Carbon: Update your Sketch symbols to V10" author="Anna Gonzales" date="April 2, 2019" href="https://medium.com/carbondesign/design-with-carbon-update-your-sketch-symbols-to-v10-ef3132ea2e97" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "designing with carbon",
              "title": "designing with carbon",
              "src": "/static/b1c4ac6137f64560f93bbb74fd35e492/fb070/designing_with_carbon.png",
              "srcSet": ["/static/b1c4ac6137f64560f93bbb74fd35e492/d6747/designing_with_carbon.png 288w", "/static/b1c4ac6137f64560f93bbb74fd35e492/09548/designing_with_carbon.png 576w", "/static/b1c4ac6137f64560f93bbb74fd35e492/fb070/designing_with_carbon.png 1152w", "/static/b1c4ac6137f64560f93bbb74fd35e492/dee76/designing_with_carbon.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Minimal CSS with Carbon" author="Josh Black" date="April 1, 2019" href="https://medium.com/carbondesign/minimal-css-with-carbon-b0c089ccfa71" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "minimal css",
              "title": "minimal css",
              "src": "/static/fd835439f8b3a8e647903c7c173e06ea/fb070/minimal_css.png",
              "srcSet": ["/static/fd835439f8b3a8e647903c7c173e06ea/d6747/minimal_css.png 288w", "/static/fd835439f8b3a8e647903c7c173e06ea/09548/minimal_css.png 576w", "/static/fd835439f8b3a8e647903c7c173e06ea/fb070/minimal_css.png 1152w", "/static/fd835439f8b3a8e647903c7c173e06ea/dee76/minimal_css.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="What to expect in Carbon 10" author="Robin Cannon" date="December 10, 2018" href="https://medium.com/carbondesign/what-to-expect-in-carbon-10-5af1bd6e25f6" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Carbon what to expect",
              "title": "Carbon what to expect",
              "src": "/static/3a8a8619b5bf2ce1b6d50219e5729509/fb070/Carbon_what_to_expect.png",
              "srcSet": ["/static/3a8a8619b5bf2ce1b6d50219e5729509/d6747/Carbon_what_to_expect.png 288w", "/static/3a8a8619b5bf2ce1b6d50219e5729509/09548/Carbon_what_to_expect.png 576w", "/static/3a8a8619b5bf2ce1b6d50219e5729509/fb070/Carbon_what_to_expect.png 1152w", "/static/3a8a8619b5bf2ce1b6d50219e5729509/dee76/Carbon_what_to_expect.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Up & Running with Carbon React in less than 5 minutes" author="Josh Black" date="October 19, 2018" href="https://medium.com/carbondesign/up-running-with-carbon-react-in-less-than-5-minutes-25d43cca059e" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Article 02",
              "title": "Article 02",
              "src": "/static/fcf5a9c51f03531d1560b086fe86bbe4/fb070/Article_02.png",
              "srcSet": ["/static/fcf5a9c51f03531d1560b086fe86bbe4/d6747/Article_02.png 288w", "/static/fcf5a9c51f03531d1560b086fe86bbe4/09548/Article_02.png 576w", "/static/fcf5a9c51f03531d1560b086fe86bbe4/fb070/Article_02.png 1152w", "/static/fcf5a9c51f03531d1560b086fe86bbe4/dee76/Article_02.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Hacktoberfest with Carbon" author="Josh Black" date="October 16, 2018" href="https://medium.com/carbondesign/hacktoberfest-with-carbon-95c48943f586" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.199999999999996%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Article 03",
              "title": "Article 03",
              "src": "/static/d1f1a8da08618409d5cab8cdcadb169f/70165/Article_03.png",
              "srcSet": ["/static/d1f1a8da08618409d5cab8cdcadb169f/d6747/Article_03.png 288w", "/static/d1f1a8da08618409d5cab8cdcadb169f/70165/Article_03.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Data Table updates in Carbon React v5.22.0" author="Josh Black" date="February 20, 2018" href="https://medium.com/carbondesign/data-table-updates-in-carbon-react-v5-22-0-6da0c24a96d6" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Article 04",
              "title": "Article 04",
              "src": "/static/94ecf307384dd9fad5a6c59768c99ab8/fb070/Article_04.png",
              "srcSet": ["/static/94ecf307384dd9fad5a6c59768c99ab8/d6747/Article_04.png 288w", "/static/94ecf307384dd9fad5a6c59768c99ab8/09548/Article_04.png 576w", "/static/94ecf307384dd9fad5a6c59768c99ab8/fb070/Article_04.png 1152w", "/static/94ecf307384dd9fad5a6c59768c99ab8/dee76/Article_04.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Introducing Carbon Themes" author="Bethany Sonefeld" date="August 11, 2017" href="https://medium.com/design-ibm/introducing-carbon-themes-83d3985a8627" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Article 05",
              "title": "Article 05",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/fb070/Article_05.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/d6747/Article_05.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/09548/Article_05.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/fb070/Article_05.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/dee76/Article_05.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
  <ResourceCard type="article" title="Carbon: Designing inside Big Blue" author="Bethany Sonefeld" date="March 30, 2017" href="https://medium.com/design-ibm/carbon-designing-inside-big-blue-8577883cfe42" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Article 06",
              "title": "Article 06",
              "src": "/static/1f96270baf66e79b710ce0067ee942d8/fb070/Article_06.png",
              "srcSet": ["/static/1f96270baf66e79b710ce0067ee942d8/d6747/Article_06.png 288w", "/static/1f96270baf66e79b710ce0067ee942d8/09548/Article_06.png 576w", "/static/1f96270baf66e79b710ce0067ee942d8/fb070/Article_06.png 1152w", "/static/1f96270baf66e79b710ce0067ee942d8/dee76/Article_06.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      